import React from "react"
import { Helmet } from "react-helmet"
import { MailIcon } from "@heroicons/react/solid"
import ContactForm from "../components/form/ContactForm"

const KontaktPage = ({ pageContext }) => {
  // Move this to it's own component consuming context.
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kontakt | FÖDA švédské potraviny</title>
      </Helmet>
      <main>
        <div className="mx-auto w-full">
          <div className="mx-auto max-w-3xl w-full  py-16 px-8">
            <div className=" prose pb-10">
              <h1>Kontakt</h1>
            </div>
            <div>
              <dl className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
                {/* <div   className="relative bg-gray-50 pt-5 px-4  sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"  >
                    <dt>
                      <div className="absolute bg-sky-700 rounded-md p-3">
                        <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">Telefon</p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-xl font-semibold text-gray-900">+420 777 936 775</p> 
                       
                    </dd>
                  </div> */}
                <a
                  href="mailto:obchod@foda.cz"
                  className="relative bg-gray-100 pt-5 px-4  sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <div className="absolute bg-sky-700 rounded-md p-3">
                      <MailIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                      E-mail
                    </p>
                  </dt>
                  <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                    <p className="text-xl font-semibold text-gray-900">
                      obchod@foda.cz
                    </p>
                  </dd>
                </a>
              </dl>

              <div className="prose pt-10 pb-3">
                <p>
                  Jsme tu pro vás. Obraťte se na nás se svými dotazy a
                  připomínkami.
                </p>
              </div>
              <div className="relative bg-gray-100  px-4 mt-3 sm:px-6 shadow rounded-lg overflow-hidden">
                <ContactForm />
              </div>

              <p className="text-base text-gray-500 font-bold"></p>

              <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase mt-10 ">
                SWEDEN GROUP s.r.o.
              </h3>
              <p className="text-base text-gray-500">
                Vodičkova 682/20, 110 00 Praha 1 - Nové Město
              </p>
              <p className="text-base text-gray-500">
                Česká republika | IČ 11877731
              </p>
              <p className=" text-gray-500 text-xs my-5">
                Spisová značka C 355756 vedená u Městského soudu v Praze
              </p>
            </div>
          </div>
        </div>
      </main>
      {/* <div >
        <div  ><GMap /></div> 
  </div> */}
    </>
  )
}

export default KontaktPage
